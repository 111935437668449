import React from 'react';
import { Player } from 'bitmovin-player';
import { UIFactory } from 'bitmovin-player/bitmovinplayer-ui';
import 'bitmovin-player/bitmovinplayer-ui.css';
import teaserImage from '../assets/teaser.jpg'

class BitmovinPlayer extends React.Component {
    
    state = {
        player: null,
        playerConfig: this.props.playerConfig.playerConfig,
        playerSource: {
            hls: this.props.playerConfig.playerSource.hls
        }
    };

    constructor(props) {
        super(props);
        this.playerDiv = React.createRef();
    }

    componentDidMount() {
        this.setupPlayer();
    }

    componentWillUnmount() {
        this.destroyPlayer();
    }

    setupPlayer() {
        const player = new Player(this.playerDiv.current, this.state.playerConfig);
        UIFactory.buildDefaultUI(player);
        player.load(this.state.playerSource).then(() => {
            this.setState({
                ...this.state,
                player
            });
            console.log('Successfully loaded source');
        }, () => {
            console.log('Error while loading source');
        });
    }

    destroyPlayer() {
        if (this.state.player != null) {
            this.state.player.destroy();
            this.setState({
                ...this.state,
                player: null
            });
        }
    }

    render() {
        return <div id='player' ref={this.playerDiv}/>;
    }
}

export default BitmovinPlayer;