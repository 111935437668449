import './App.css';
import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Live from "./components/Live";
import Teaser from "./components/Teaser";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-224724907-1');

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App pt-5">
      <Routes>
        <Route path="/" element={<Live />} />
        <Route path="/live" element={<Live />} />
      </Routes>
    </div>
  );
}
 
export default App;