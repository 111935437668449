import React, {useState,useEffect } from 'react'
import {useNavigate} from "react-router-dom"
import BitmovinPlayer from './BitmovinPlayer'
import logo from '../assets/logo.png'
import ReactGA from 'react-ga';

const Live = (props) => {
    
    let navigate = useNavigate();
    const apiEndPoint = 'https://hjz13nrhx2.execute-api.us-east-1.amazonaws.com/prod';

    const [authUser, setAuthUser] = useState(false);
    const [playerConfig, setPlayerConfig] = useState();
    const [isLoadingStreamingConfig, setIsLoading] = useState(true);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        const isAuthUser =  true;
        if(isAuthUser){
            setAuthUser(true);
            fetch(`${apiEndPoint}/configs/streamings/`)
                .then(res => {
                    return res.json();
                }).then(res => {
                    setPlayerConfig(res.event_streaming);
                    setIsLoading(false);
                });
        }
        else{
            navigate('/');
        }
    }, []);
    
    if(authUser && !isLoadingStreamingConfig){
        return (
            <div className='live'>
                <div className='row'>
                    <div className='col text-end'>
                        <img src={logo} alt="Ilusión"/>
                    </div>
                </div>
                <div className='row my-5'>
                    <div className='col-md-8 offset-md-2'>
                        <BitmovinPlayer playerConfig={playerConfig}  />
                    </div>
                </div>
            </div> 
        )
    }
    
    return(
        <div className='live'>
            <div className='row'>
                <div className='col text-end'>
                    <img src={logo} alt="Ilusión"/>
                </div>
            </div>
            <div className='row my-5'>
                <div className='col-md-8 offset-md-2 text-center'>
                    <p>Cargando...</p>
                    <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default Live;
